<template lang="pug">
div 
	transition(name="fadeLoading")
		Loading(v-show="isActiveLoading")
	router-view
</template>

<script>
import Loading from "@/components/Loading/index.vue";
import { nextTick } from "vue";

export default {
    components: {
        Loading,
    },
    computed: {
        isActiveLoading() {
            return this.$store.state.isActiveLoading;
        },
    },
    mounted() {
        nextTick(() => {
            let vm = this;
            vm.$store.dispatch("initLineLiff");
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        });
    },
};
</script>

<style lang="sass">
@import "@/assets/sass/_reset.sass"
@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
@import "@/assets/sass/_layout.sass"
</style>
