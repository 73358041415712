<script>
export default {
    name: "Guide",
    data() {
        return {
            nowGuide: 1,
        };
    },
    computed: {
        userNbInfo() {
            return this.$store.state.userNbInfo;
        },
    },
    methods: {
        nextGuide() {
            // console.log("nextGuide");
            let vm = this;
            if (vm.userNbInfo.data.user.level === "nber") {
                if (vm.nowGuide < 3) {
                    vm.nowGuide++;
                } else {
                    vm.nowGuide = null;
                    vm.$store.dispatch("getUserNBInfo");
                }
            } else if (
                vm.userNbInfo.data.user.level === "vip" &&
                vm.userNbInfo.data.user.isApproval === "N"
            ) {
                if (vm.nowGuide < 3) {
                    vm.nowGuide++;
                } else {
                    vm.nowGuide = null;
                    vm.$store.dispatch("getUserNBInfo");
                }
            } else if (
                vm.userNbInfo.data.user.level === "vip" &&
                vm.userNbInfo.data.user.isApproval === "Y"
            ) {
                if (vm.nowGuide < 2) {
                    vm.nowGuide++;
                } else {
                    vm.nowGuide = null;
                    vm.$store.dispatch("getUserNBInfo");
                }
            }
        },
    },
};
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
