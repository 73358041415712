<script>
export default {
    name: "Nav",
    computed: {
        userNbInfo() {
            return this.$store.state.userNbInfo;
        },
        migoLiffId() {
            return this.$store.state.migoLiffId;
        },
    },
};
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
