<script>
import Nav from "@/components/Nav/index.vue";
import Guide from "@/components/Guide/index.vue";
import { nextTick } from "vue";
import JsBarcode from "jsbarcode";
export default {
    name: "Home",
    data() {
        return {
            descriptionTarget: "",
            isActiveEvent: true,
        };
    },
    components: {
        Nav,
        Guide,
    },
    computed: {
        userNbInfo() {
            return this.$store.state.userNbInfo;
        },
        migoLiffId() {
            return this.$store.state.migoLiffId;
        },
    },
    watch: {
        userNbInfo: function () {
            let vm = this;
            if (!vm.userNbInfo.data.user.isBind) {
                vm.$router.push("/welcome");
            } else {
                nextTick(() => {
                    vm.initJsBarcode();
                    let progessLine =
                        (vm.userNbInfo.data.user.consumeNow /
                            vm.userNbInfo.data.user.consumeGoal) *
                        100;
                    if (progessLine >= 100) {
                        progessLine = 100;
                    }
                    document.documentElement.style.setProperty(
                        "--progessLine",
                        `${progessLine}%`
                    );
                });
            }
        },
    },
    mounted() {
        nextTick(() => {
            let vm = this;
            if (vm.userNbInfo.data) {
                if (vm.userNbInfo.data.user.isBind) {
                    vm.initJsBarcode();
                } else vm.$router.push("/welcome");
            }
            let start = "2024-09-08 00:00";
            let now = new Date();
            let reg = new RegExp("-", "g");

            if (new Date(start.replace(reg, "/")) <= now) {
                vm.isActiveEvent = false;
            }
        });
    },
    methods: {
        sendGtm(str) {
            window.dataLayer.push({
                event: "horyuken_event",
                event_name: str,
                event_time: new Date().toLocaleString(),
            });
        },
        initJsBarcode() {
            // console.log("JsBarcode");
            let vm = this;
            JsBarcode("#barcode", vm.userNbInfo.data.user.customId, {
                format: "CODE128",
                lineColor: "#151415",
                // width: 4.5,
                height: 60,
                displayValue: false,
                background: "transparent",
            });
        },
        showDescription(target) {
            // console.log("showDescription");
            let vm = this;
            if (target === vm.descriptionTarget) {
                vm.descriptionTarget = "";
            } else {
                vm.descriptionTarget = target;
            }
        },
        changeToLocaleString(str) {
            // console.log("changeToLocaleString", str);
            return typeof str === "number" ? str.toLocaleString() : "0";
        },
    },
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
