import { createStore } from 'vuex'
import liff from "@line/liff";
import axios from "axios";
import router from "@/router"
import { nextTick } from "vue";
import VConsole from "vconsole";



if(process.env.NODE_ENV !== 'production'){
	const vConsole = new VConsole({ theme: "dark" });
	console.log(vConsole);
}

export default createStore({
	strict: true,
	state: {
		isActiveLoading: true,//控制Loading開關
		userLineInfo: {},
		userNbInfo: {},
		liffID: process.env.VUE_APP_liffID,
		baseUri: process.env.VUE_APP_baseUri,
		hostUrl: process.env.VUE_APP_hostUrl,
		migoBindLiffId: process.env.VUE_APP_migoBindLiffId,
		migoLiffId: process.env.VUE_APP_migoLiffId
	},
	mutations: {
		loadingToggle(state,value){
			state.isActiveLoading=value
		},
		setUserLineInfo(state,{token,userId}){
			state.userLineInfo.token=token
			state.userLineInfo.userId=userId
		},
		setUserNBInfo(state,data){
			state.userNbInfo=data
		},
	},
	actions: {
		getDataFromCookie(context){
			// console.log("getDataFromCookie")
			let cookieAry = document.cookie.split(';');
			let newCookiAry=[]
			//去除頭尾空格後找到開頭為userNbInfo之cookie
			cookieAry.forEach((element,index) => {
				newCookiAry[index]=element.trim()
			});
			let cookieStr=newCookiAry.filter((value) => value.match(/^userNbInfo/g))

			if(cookieStr.length===0){
				context.dispatch("userLogin")
			}else{
				let data=JSON.parse(decodeURIComponent(cookieStr[0].split('=')[1].replace(/"/g,'')))
				context.commit("setUserNBInfo",data)
				nextTick(() => {
					context.commit('loadingToggle',false)
				});
			}
		},
		userLogin(context){
			// console.log("userLogin")
			axios.post(context.state.hostUrl+"api/auth/login",
				{
					"idToken":context.state.userLineInfo.token,
					"isFirst": router.currentRoute._value.name==='Home' ? true : false
				})
				.then(function (response) {
					// console.log(response)
					if(response.status===200){
						let data=response.data
						context.commit("setUserNBInfo",data)

						//設定存放使用者資料，兩小時後過期之cookie
						if (data.data.user.isBind&&process.env.NODE_ENV === 'production'){
							document.cookie = `userNbInfo=${encodeURIComponent(JSON.stringify(data))}; max-age=72000; path=/`;
						}

						nextTick(() => {
							context.commit('loadingToggle',false)
						});
					}
				})
				.catch(function (error) {
					if (error.response.status === 401) {
						document.cookie = 'LIFF_STORE:expires:' + context.state.liffID + '= ; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
						location.reload()
					}
					console.log(error);
				});
		},
		getUserNBInfo(context){
			// console.log("getUserNBInfo")
			axios.get(context.state.hostUrl+"api/auth/me",
				{
					headers: {
						"Authorization": `Bearer ${context.state.userNbInfo.access_token}`,
						"Content-Type": "application/json",
					},
				})
				.then(function (response) {
					// console.log(response)
					if(response.status===200){
						let data=response.data
						context.commit("setUserNBInfo",data)
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		initLineLiff(context){
			// console.log("initLineLiff")
			if (!context.state.liffID) {
				window.alert("LIFF ID error");
			} else {
				liff.init({
					liffId: context.state.liffID,
				}).then(function () {
					if (!liff.isLoggedIn()) {
						liff.login({
							redirectUri: context.state.baseUri,
						});
					} else {
						let token=liff.getIDToken()
						liff.getProfile().then(function (profile) {
							let userId=profile.userId;
							context.commit("setUserLineInfo",{token,userId})
							context.dispatch("getDataFromCookie")
							
							window.dataLayer = window.dataLayer || []
							window.dataLayer.push({
								'event': 'uid_loaded',
								'uid': userId
							});
						});
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	modules: {
	}
})
